import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';

import './src/styles/fonts.scss'
import './src/styles/main.scss'

export const wrapRootElement = ({ element }) => {
    return (
        <ChakraProvider>
            {element}
        </ChakraProvider>
    )
};

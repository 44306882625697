import { extendTheme } from '@chakra-ui/react'
const theme = {
  fonts: {
    body: 'Poppins, sans-serif',
    heading: 'Georgia, serif',
    mono: 'Menlo, monospace',
  },
}

export default extendTheme(theme)
